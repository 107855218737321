import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="resource-modal"
export default class extends Controller {
  initialize() {
    if ($('#resource-modal').data('bs.modal')?._isShown) {
      return;
    }

    $('#resource-modal').on('hidden.bs.modal', () => {
      this.disconnect();
    });

    $('#resource-modal').modal('show');
    
    document.addEventListener('turbo:submit-end', (e) => {
      if (e.detail?.formSubmission?.fetchRequest?.body?.get('_method') === 'delete' && e.detail?.formSubmission?.fetchRequest?.url?.pathname?.includes('repairs')) {
        $('#resource-modal').modal('hide');
        return;
      }

      if (e.detail?.formSubmission?.fetchRequest?.body?.get('_method') === 'delete') {
        return;
      }

      if (e.detail.success && e.detail.fetchResponse.response.status == 303) {
        return;
      }

      if (e.detail.success && e.detail.formSubmission.submitter.formMethod != 'get') {
        if (e.detail.fetchResponse.response.status == 303) {
          return;
        }
        if (e.detail.fetchResponse.response.status == 204) {
          return;
        }

        if (e.detail?.formSubmission?.fetchRequest?.url?.pathname.includes('comments')){
          return;
        }

        $('#resource-modal').modal('toggle')

        if (e.detail.fetchResponse.response.url != undefined) {
          if (e.detail.fetchResponse.response.url == window.location.href) {
            window.location.reload();
          } else {
            Turbo.visit(e.detail.fetchResponse.response.url, { action: 'replace' })
          }
        }
      }
    });
  }

  disconnect() {
    console.log('000resource-modal controller disconnected')
  }
}
